import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx, Global, keyframes } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import { Heading2 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import PageWrapper from '../PageWrapper/PageWrapper';
import Button from '../../atoms/Buttons/Button/Button';
import Squiggle from '../../atoms/Dividers/Squiggle/Squiggle';
import Image from '../../atoms/Image/Image';
import Section from '../../molecules/Section/Section';
import bubblePerson1 from './assets/resized/person-1-70x70.png';
import bubblePerson2 from './assets/resized/person-2-70x70.png';
import bubblePerson3 from './assets/resized/person-3-70x70.png';
import bubblePerson4 from './assets/resized/person-4-70x70.png';
import bubblePerson5 from './assets/resized/person-5-70x70.png';
import bubblePerson6 from './assets/resized/person-6-70x70.png';
import bubblePerson7 from './assets/resized/person-7-70x70.png';
import bubblePerson8 from './assets/resized/person-8-70x70.png';

const bubblesLeft = [
  bubblePerson1,
  bubblePerson2,
  bubblePerson3,
  bubblePerson4,
];
const bubblesRight = [
  bubblePerson5,
  bubblePerson6,
  bubblePerson7,
  bubblePerson8,
];

const BubbleCtaSection = ({
  button,
  className,
  heading,
  sectionStyles,
  text,
}) => {
  // Check if props exist that are required to render
  if (!bubblesLeft || !bubblesRight || !heading || !text) {
    return null;
  }

  const [isIntersected, setIsIntersected] = useState(false);
  const animationTrigger = React.createRef();
  useEffect(() => {
    const addActiveClass = entries => {
      if (entries[0].isIntersecting) {
        setIsIntersected(true);
      }
    };

    if (window && window.IntersectionObserver) {
      const observer = new IntersectionObserver(addActiveClass, {
        threshold: 0.8,
      });
      observer.observe(animationTrigger.current);
    }
  }, []);

  const squiggleCss = css`
    position: relative;
    padding: ${uc('0 30px')};

    @media (${breakpoints.desktop}) {
      max-width: 85%;
      margin-top: ${uc('100px')};
    }
  `;

  const bubbleDriftKeyframes = keyframes`
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    20% {
      opacity: 1;
    }
    80% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1.0);
    }
  `;

  const sectionCss = css`
    position: relative;
    text-align: center;

    @media (${breakpoints.tablet}) {
      padding-bottom: ${uc('60px')};
    }

    @media (${breakpoints.mobile}) {
      padding-bottom: 0;
    }

    ${className}
  `;

  const sectionBgCss = css`
    position: absolute;
    top: ${uc('-99px')};
    left: 0;
    z-index: 0;
    width: 100%;
    height: 270%;
    background: ${colors.lightGray.six};
    transform: skewY(-11deg);
  `;

  const bubblesLeftRightCss = css`
    position: absolute;
    width: 100%;
    height: 100%;

    .bubble {
      position: absolute;
      width: ${uc('53px')};
      height: ${uc('53px')};
      color: ${colors.white};
      font-size: ${uc('28px')};
      font-family: ${fontFamilies.proximaSoft};
      background: ${colors.lightGray.one};
      border-radius: 50%;
      transform: translate(0, 0);
      animation: 200ms both;

      .-active & {
        animation-name: ${bubbleDriftKeyframes};
      }

      .-inactive & {
        opacity: 0;
        animation-name: none;
      }

      &.-person {
        width: ${uc('70px')};
        height: ${uc('70px')};
        box-shadow: ${uc('1px 10px 25px')} rgba(0, 0, 0, 0.225);

        &::after {
          display: none;
        }
      }

      &::after {
        position: absolute;
        top: calc(50% + 0.05em);
        left: 50%;
        transform: translate(-50%, -50%);
        content: '+';
      }
    }
  `;

  const bubbleBreakpoints = {
    first: uc('1200px'),
    second: uc('750px'),
    third: uc('600px'),
  };
  const bubblesLeftCss = css`
    top: ${uc('-147px')};
    left: ${uc('-271px')};

    .bubble {
      &:nth-of-type(1) {
        top: ${uc('-30px')};
        left: ${uc('90px')};
        animation-delay: 10ms;
      }

      &:nth-of-type(2) {
        top: ${uc('97px')};
        left: ${uc('280px')};
        animation-delay: 100ms;

        @media (max-width: ${bubbleBreakpoints.second}) {
          display: none;
        }
      }

      &:nth-of-type(3) {
        top: ${uc('235px')};
        left: ${uc('141px')};
        animation-delay: 70ms;
      }

      &:nth-of-type(4) {
        top: ${uc('310px')};
        left: ${uc('368px')};
        animation-delay: 120ms;

        @media (max-width: ${bubbleBreakpoints.third}) {
          top: 180%;
        }
      }

      &:nth-of-type(5) {
        top: ${uc('90px')};
        left: ${uc('15px')};
        background: ${colors.mintHover};
        animation-delay: 0ms;
      }

      &:nth-of-type(6) {
        top: ${uc('300px')};
        left: ${uc('-31px')};
        background: ${colors.primary};
        animation-delay: 10ms;

        @media (max-width: ${bubbleBreakpoints.third}) {
          top: 180%;
        }
      }

      &:nth-of-type(7) {
        top: ${uc('20px')};
        left: ${uc('410px')};
        background: ${colors.marigold};
        animation-delay: 130ms;

        @media (max-width: ${bubbleBreakpoints.first}) {
          top: ${uc('60px')};
          left: 60%;
        }
      }
    }
  `;

  const bubblesRightCss = css`
    top: ${uc('-142px')};
    right: ${uc('-263px')};

    .bubble {
      &:nth-of-type(1) {
        top: ${uc('47px')};
        right: ${uc('446px')};
        animation-delay: 140ms;

        @media (max-width: ${bubbleBreakpoints.third}) {
          right: 80%;
        }
      }

      &:nth-of-type(2) {
        top: ${uc('24px')};
        right: ${uc('64px')};
        animation-delay: 40ms;
      }

      &:nth-of-type(3) {
        top: ${uc('182px')};
        right: ${uc('302px')};
        animation-delay: 100ms;

        @media (max-width: ${bubbleBreakpoints.second}) {
          display: none;
        }
      }

      &:nth-of-type(4) {
        top: ${uc('294px')};
        right: ${uc('76px')};
        animation-delay: 70ms;
      }

      &:nth-of-type(5) {
        top: ${uc('296px')};
        right: ${uc('219px')};
        background: ${colors.marigold};
        animation-delay: 100ms;
      }

      &:nth-of-type(6) {
        top: ${uc('358px')};
        right: ${uc('447px')};
        background: ${colors.primary};
        animation-delay: 130ms;

        @media (max-width: ${bubbleBreakpoints.third}) {
          top: 170%;
          right: 80%;
        }
      }

      &:nth-of-type(7) {
        top: ${uc('135px')};
        right: ${uc('-10px')};
        background: ${colors.mintHover};
        animation-delay: 0ms;
      }
    }
  `;

  const bubbleSectionCss = css`
    display: flex;
    min-height: ${uc('480px')};
  `;

  const pageWrapperCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const headingCss = css`
    color: ${colors.primary};
    font-size: ${fontSizes.thirtyEight};

    @media (${breakpoints.tablet}) {
      padding: ${uc('0 20px')};
      font-size: ${fontSizes.thirty};
    }
  `;

  const textCss = css`
    max-width: ${uc('400px')};
    color: ${colors.lightGray.five};
    font-size: ${fontSizes.sixteen};
    line-height: ${lineHeights.copy};
    text-align: center;

    @media (${breakpoints.tablet}) {
      padding: ${uc('0 20px')};
    }
  `;

  const renderedLeftBubbles = bubblesLeft
    ? bubblesLeft.map(bubble => (
        <div key={`bubble-${Math.random()}`} className="bubble -person">
          <Image src={`${bubble}`} alt="decorative bubble person" />
        </div>
      ))
    : null;

  const renderedRightBubbles = bubblesRight
    ? bubblesRight.map(bubble => (
        <div key={`bubble-${Math.random()}`} className="bubble -person">
          <Image src={`${bubble}`} alt="decorative bubble person" />
        </div>
      ))
    : null;

  return (
    <Section
      sectionStyles={sectionStyles}
      css={sectionCss}
      className={isIntersected ? '-active' : '-inactive'}
    >
      <Global
        styles={css`
          main {
            > * {
              &:nth-last-of-type(2) {
                position: relative;
                z-index: 1;
              }
            }
          }
        `}
      />

      <div css={sectionBgCss} />
      {/* Have to target DOM element to use ref */}
      <div css={bubbleSectionCss} ref={animationTrigger}>
        <PageWrapper css={pageWrapperCss}>
          <div
            css={[bubblesLeftRightCss, bubblesLeftCss]}
            className="bubbleGroup"
          >
            {renderedLeftBubbles}
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
          </div>
          <div
            css={[bubblesLeftRightCss, bubblesRightCss]}
            className="bubbleGroup"
          >
            {renderedRightBubbles}
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
          </div>
          <Heading2 css={headingCss}>{heading}</Heading2>
          <Text css={textCss}>{text}</Text>
          {button && button.text ? (
            <div>
              <Button shadow _key="bubble-cta" {...button}>
                {button.text}
              </Button>
            </div>
          ) : null}
        </PageWrapper>
      </div>
      <Squiggle css={squiggleCss} marginTop="0" marginBottom={uc('30px')} />
    </Section>
  );
};

BubbleCtaSection.propTypes = {
  button: PropTypes.shape({ text: PropTypes.string }),
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  sectionStyles: PropTypes.shape({}),
  text: PropTypes.string.isRequired,
};

BubbleCtaSection.defaultProps = {
  button: null,
  sectionStyles: {},
  className: '',
};

export default BubbleCtaSection;
